import { DropdownWithLabel } from '@visto-tech/forms';
import { Stripe } from 'dataLayer/Stripe';
import Button from 'elements/Button';
import Loading from 'elements/Loading';
import { Modal } from 'elements/Modal/Modal';
import Text from 'elements/Text';
import { range } from 'lodash';
import { FC, useState } from 'react';
import { Check, CheckCircle } from 'react-feather';

export interface PricingTablePricePropTypes {
  title: string;
  subTitle: string;
  price: number | string;
  stripePriceId?: string;
  details: string[];
  highlight?: string;
  isCurrent?: boolean;
  shouldSelectQuantity?: boolean;
  onClick?: () => void;
  ctaText?: string;
  hideCta?: boolean;
}

export const PricingTablePrice: FC<PricingTablePricePropTypes> = ({
  title,
  subTitle,
  price,
  stripePriceId,
  details,
  highlight,
  isCurrent = false,
  shouldSelectQuantity = false,
  onClick,
  ctaText = 'Start now',
  hideCta = false,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [seats, setSeats] = useState<number | null>(null);

  const getCheckoutUrl = async () => {
    if (!stripePriceId) {
      return null;
    }

    setIsLoading(true);

    const checkoutUrl = await Stripe.getStripeCheckoutUrl({
      priceId: stripePriceId,
      quantity: seats ?? undefined,
    });

    if (!checkoutUrl) {
      setIsLoading(false);
      return null;
    }

    window.location.href = checkoutUrl;

    setIsLoading(false);
  };

  if (isLoading) {
    <Loading.Default />;
  }

  return (
    <>
      <div className={`visto-component relative flex flex-col`}>
        {highlight && (
          <Text.Paragraph className="text-white bg-visto-light-blue px-3 py-1 fs14 font-semibold absolute -top-4 left-1/2 -translate-x-1/2 rounded-md">
            {highlight}
          </Text.Paragraph>
        )}
        <Text.Paragraph className="font-semibold mb-2 text-blue-600">
          {title}
        </Text.Paragraph>
        {typeof price === 'string' ? (
          <Text.Paragraph className="fs24 font-bold mb-1">
            {price}
          </Text.Paragraph>
        ) : (
          <Text.Paragraph className="fs24 font-bold mb-1">
            ${price}/<span className="fs14">month</span>
          </Text.Paragraph>
        )}
        <Text.Paragraph className="fs14 font-semibold">
          {subTitle}
        </Text.Paragraph>
        <div className="mt-6">
          {details.map((detail, i) => {
            return (
              <div
                key={`detail-${i}`}
                className="flex items-center mb-3 last:mb-0"
              >
                <Check
                  size={18}
                  strokeWidth={3}
                  className="text-blue-600 mr-2 min-w-4"
                />
                <Text.Paragraph className={`fs14 font-semibold`}>
                  {detail}
                </Text.Paragraph>
              </div>
            );
          })}
        </div>
        {!hideCta && (
          <div className="mt-auto">
            {stripePriceId ? (
              <Button
                className="mt-10 w-full"
                onClick={async () => {
                  if (shouldSelectQuantity) {
                    setIsModalOpen(true);
                  } else {
                    await getCheckoutUrl();
                  }
                }}
                isLoading={isLoading}
                disabled={isLoading}
                size="large"
              >
                {ctaText}
              </Button>
            ) : onClick ? (
              <Button className="mt-10 w-full" onClick={onClick} size="large">
                {ctaText}
              </Button>
            ) : isCurrent ? (
              <Text.Paragraph className="mt-10 px-6 py-2 rounded-full border border-green-500 text-green-700 font-bold flex items-center justify-center fs14 bg-emerald-50">
                <CheckCircle className="text-green-700 mr-2" size={18} /> Active
                plan
              </Text.Paragraph>
            ) : (
              <Text.Paragraph className="mt-10 px-6 py-2 rounded-full border border-green-500 text-green-700 font-bold flex items-center justify-center fs14 bg-emerald-50">
                Send us a message for Enterprise Plans
              </Text.Paragraph>
            )}
            <img
              alt="powered by Stripe"
              className="mx-auto mt-4 w-24"
              src="/powered-stripe.svg"
            />
          </div>
        )}
      </div>
      <Modal
        image="bot"
        variation="medium"
        modalOpen={isModalOpen}
        setModalOpen={setIsModalOpen}
        title="Select Number of Users"
        subTitle="How many users (seats) would you like to make available? You will be able to add or remove these any time."
        onModalClose={() => setSeats(null)}
        cta={{
          text: 'Continue',
          onClick: getCheckoutUrl,
          isDisabled: isLoading,
          isLoading: isLoading,
        }}
      >
        <DropdownWithLabel
          onChange={(e: any) => setSeats(Number(e.currentTarget.value))}
          id={'seats'}
          name={'seats'}
          dropdownProps={{
            defaultValue: '',
            children: [
              <option key={`initial-empty-value`} value="" disabled>
                Select a number seats
              </option>,
              ...range(1, 31).map((val) => (
                <option key={val} value={val}>
                  {val}
                </option>
              )),
            ],
          }}
          formFieldSetProps={{
            className: 'mb-2',
          }}
        >
          How many users would you like to make available?
        </DropdownWithLabel>
      </Modal>
    </>
  );
};
